@import './assets/sass/theme';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

body {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.pageTitle {
  font-weight: normal;
  font-size: 26px;
  margin-bottom: 20px;
}

#pageWrap {
  margin-left: 230px;
  padding: 30px;
}

.blaise-progress-top {
  margin: 20px 0px;
  text-align: center;
}
