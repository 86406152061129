@import '../../assets/sass/theme';

.logoNavbar {
  width: 180px;
  padding: 20px;
}

.logoutBox {
  margin-top: auto;
}

.navBox {
  height: 100vh;
}

#logoutButton {
  color: $red;
  border-color: $red;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 20px auto;
}

// Removing default navbar border
.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  border: none;
}

.selectedNavbarItem {
  background-color: $white !important;
  color: $blaise-green !important;
  border-radius: 30px 0 0 30px;
}
