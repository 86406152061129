@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logoNavbar{width:180px;padding:20px}.logoutBox{margin-top:auto}.navBox{height:100vh}#logoutButton{color:#a23939;border-color:#a23939;text-transform:none;font-family:'Source Sans Pro', sans-serif;margin:20px auto}.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft{border:none}.selectedNavbarItem{background-color:#fff !important;color:#00cdad !important;border-radius:30px 0 0 30px}

body{font-family:'Source Sans Pro', sans-serif !important}.pageTitle{font-weight:normal;font-size:26px;margin-bottom:20px}#pageWrap{margin-left:230px;padding:30px}.blaise-progress-top{margin:20px 0px;text-align:center}

.container{height:100%;width:100%;display:flex;justify-content:center;align-items:center}.container .blaise-loader{height:60px;width:60px}

.paper{height:calc((100vh - 60px - 30vh - 24px - 53px - 12px - 18px) / 2);padding:24px !important;box-sizing:border-box;display:flex;flex-direction:column;justify-content:space-between;overflow:hidden;background-color:#f2faf8 !important}.paper .subtitle{text-align:center}@media screen and (max-width: 1500px){.paper{height:calc((100vh - 60px - 20vh - 24px - 53px - 12px - 18px) / 2);padding:12px !important}}@media screen and (max-width: 1200px){.subtitle{font-size:14px}}.title-container{margin-bottom:12px}

.bus-hero-image-container{overflow:hidden;border-radius:10px;margin-bottom:24px}.bus-hero-image-container .bus-hero-image{width:100%;height:30vh;object-fit:cover;object-position:center calc(100% - 5%);-webkit-transform:scale(1.1);transform:scale(1.1);-webkit-filter:brightness(0.7);filter:brightness(0.7)}@media screen and (max-width: 1500px){.bus-hero-image-container .bus-hero-image{height:20vh}}.larger-text{font-size:18px !important;text-align:center}@media screen and (max-width: 1200px){.larger-text{font-size:14px !important}}.centered-container{display:flex;flex-direction:column;justify-content:center;align-items:center;grid-gap:6px;gap:6px}.centered-container .analytics-summary-item{display:flex;justify-content:space-between;width:100%}@media screen and (max-width: 1500px){.centered-container{grid-gap:2px;gap:2px}}.contact-item{display:flex;flex-direction:column;align-items:center;justify-content:flex-start;grid-gap:6px;gap:6px}

